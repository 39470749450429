import React from 'react';
import { graphql } from 'gatsby';
import Service from '../../components/Services/Service';
import MobileWhite from '/src/assets/servicesIcons/mobile_white.svg';
import { Text } from '../../components/Common';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { SERVICES_ORIGINAL_BREADCRUMBS } from '../../constants';

const MobilePage = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(
    'Mobile Develop',
    SERVICES_ORIGINAL_BREADCRUMBS
  );

  return (
    <Service
      header="services page mobile header"
      content="Mobile Development Description"
      seo={{
        title: 'SEO title service mobile',
        description: 'SEO description service mobile',
        breadcrumbsElementsSchema,
      }}
      Icon={MobileWhite}
    >
      <>
        <Text
          text="Mobile Development Description1"
          variant="body1"
          component="p"
        />
        <br />
        <Text
          text="Mobile Development Description2"
          variant="body1"
          component="p"
        />
        <br />
        <Text
          text="Mobile Development Description3"
          variant="body1"
          component="p"
        />
        <br />
        <Text
          text="Mobile Development Description4"
          variant="body1"
          component="p"
        />
        <br />
        <Text
          text="Mobile Development Description5"
          variant="body1"
          component="p"
        />
      </>
    </Service>
  );
};

export default MobilePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
